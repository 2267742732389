<template>
  <div class="calculate">
    <div class="container">
      <div class="calculate__title">
        Расчет параметров различных типов разгерметизации магистрального
        трубопровода
      </div>
      <div class="calculate__block">
        <div class="calculate__subtitle">
          Исходные данные:
        </div>
        <div class="calculate__row">
          <div class="calculate__row-label">
            Тип магистрального трубопровода:
          </div>
          <div class="calculate__row-input">
            <el-select v-model="typeOfPipeline">
              <el-option
                v-for="item in types"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div class="calculate__row">
          <div class="calculate__row-label">
            Диаметр магистрального трубопровода, мм
          </div>
          <div class="calculate__row-input">
            <el-input-number
              v-model="pipelineDiameter"
              controls-position="right"
              :min="1"
            />
          </div>
        </div>
        <div class="calculate__row">
          <div class="calculate__row-label">
            Толщина стенки магистрального трубопровода, мм
          </div>
          <div class="calculate__row-input">
            <el-input-number
              v-model="wallThickness"
              controls-position="right"
              :min="1"
            />
          </div>
        </div>
        <div class="calculate__row">
          <div class="calculate__row-label">
            Минимальная глубина заложения магистрального трубопровода, м
          </div>
          <div class="calculate__row-input">
            <el-input-number
              v-model="layingDepth"
              controls-position="right"
              :min="1"
            />
          </div>
        </div>
      </div>


      <div class="calculate__block">
        <div class="calculate__subtitle">
          Участки переходов, выполненные методом наклонно-направленного бурения:
        </div>
        <div class="calculate__radio">
          <el-radio v-model="directionalDrillPiece" label="yes">
            переход, выполненный методом наклонно-направленного бурения;
          </el-radio>
          <el-radio v-model="directionalDrillPiece" label="no">
            иной участок трубопровода
          </el-radio>
        </div>
      </div>

      <div class="calculate__block">
        <div class="calculate__subtitle">
          Участки переходов через искуственные препятствия:
        </div>
        <div class="calculate__radio">
          <el-radio v-model="crossingArtificialObstacles" label="yes">
            переход через автодороги, железные дороги и инженерные коммуникации;
          </el-radio>
          <el-radio v-model="crossingArtificialObstacles" label="no">
            иной участок трубопровода или на нем предусмотрен защитный футляр
            (кожух) из стальных труб с герметизацией межтрубного пространства.
          </el-radio>
        </div>
      </div>

      <div class="calculate__block">
        <div class="calculate__subtitle">
          Применение материалов и средств контроля при строительстве:
        </div>
        <div class="calculate__radio">
          <el-radio v-model="applicationOfMaterials" label="norm">
            трубопровод построен в соответствии с требованиями нормативных документов;
          </el-radio>
          <el-radio v-model="applicationOfMaterials" label="improve">
            трубопровод построен с использованием улучшенных материалов и дополнительных средств контроля при строительстве и последующей эксплуатации трубопроводов.
          </el-radio>
        </div>
      </div>

      <div class="calculate__block">
        <div class="calculate__subtitle">
          Применение систем защиты от коррозии:
        </div>
        <div class="calculate__radio">
          <el-radio v-model="corrosionProtection" label="norm">
            трубопровод построен в соответствии с требованиями нормативных документов;
          </el-radio>
          <el-radio v-model="corrosionProtection" label="improve">
            трубопровод построен с использованием улучшенной системы защиты (тип и качество изоляционного покрытия, электрохимическая защита, внутритрубная диагностика и т.п.).
          </el-radio>
        </div>
      </div>

      <div class="calculate__block">
        <div class="calculate__subtitle">
          Участки переходов через естественные препятствия:
        </div>
        <div class="calculate__radio">
          <el-radio v-model="crossingNaturalObstacles" label="water">
            переход через водные преграды;
          </el-radio>
          <el-radio v-model="crossingNaturalObstacles" label="swamp">
            переход через заболоченные участки;
          </el-radio>
          <el-radio v-model="crossingNaturalObstacles" label="no">
            трубопровод не проходит через водные преграды или заболоченные участки или выполнен методом наклонно-направленного бурения.
          </el-radio>
        </div>
      </div>


      <div class="calculate__button">
        <ButtonElement text="Рассчитать" @click="calculate"/>
      </div>
      <div class="calculate__results">
        <div class="calculate__results-title">
          Результаты расчета:
        </div>
        <div class="calculate__results-blocks">
          <div class="calculate__results-block">
            <div class="calculate__results-name">
              Прокол:
            </div>
            <div class="calculate__results-text">
              эквивалентный диаметр прокола, мм:
            </div>
            <div v-if="holeDiameterFirstType" class="calculate__results-value">
              {{ holeDiameterFirstType.toFixed(0) }}
            </div>
            <div class="calculate__results-text">
              удельная частота разгерметизации, год-1*м-1:
            </div>
            <div v-if="failureRateFirstType" class="calculate__results-value">
              {{ failureRateFirstType.toExponential(3) }}
            </div>
          </div>
          <div class="calculate__results-block">
            <div class="calculate__results-name">
              Отверстие:
            </div>
            <div class="calculate__results-text">
              эквивалентный диаметр отверстия, мм:
            </div>
            <div v-if="holeDiameterSecondType" class="calculate__results-value">
              {{ holeDiameterSecondType.toFixed(0) }}
            </div>
            <div class="calculate__results-text">
              удельная частота разгерметизации, год-1*м-1:
            </div>
            <div v-if="failureRateSecondType" class="calculate__results-value">
              {{ failureRateSecondType.toExponential(3) }}
            </div>
          </div>
          <div class="calculate__results-block">
            <div class="calculate__results-name">
              Разрыв:
            </div>
            <div class="calculate__results-text">
              эквивалентный диаметр разрыва, мм:
            </div>
            <div v-if="holeDiameterThirdType" class="calculate__results-value">
              {{ holeDiameterThirdType.toFixed(0) }}
            </div>
            <div class="calculate__results-text">
              удельная частота разгерметизации, год-1*м-1:
            </div>
            <div v-if="failureRateThirdType" class="calculate__results-value">
              {{ failureRateThirdType.toExponential(3) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from '../components/elements/ButtonElement'

export default {
  name: 'Calculate',
  components: {
    ButtonElement,
  },
  data() {
    return {
      types: [
        {
          label: 'Газопровод',
          value: 'gasPipeline',
        },
        {
          label: 'Нефтепровод',
          value: 'oilPipeline',
        },
      ],
      typeOfPipeline: 'gasPipeline', //тип магистрального трубопровода
      pipelineDiameter: 1, //диаметр магистрального трубопровода, м
      wallThickness: 1, //толщина стенки магистрального трубопровода, мм
      layingDepth: 1, //минимальная глубина заложения магистрального трубопровода, м

      directionalDrillPiece: 'yes',
      crossingArtificialObstacles: 'yes',
      applicationOfMaterials: 'norm',
      corrosionProtection: 'norm',
      crossingNaturalObstacles: 'water',



      baselineFailureRate: 0, //базовая частота резгерметизации магистральноготрубопровода, год-1*м-1
      knnb: 0, //поправочный коэффициент для участков переходов, выполненных методом наклонно направленного бурения (ННБ)
      kper1: 2, // поправочный для участков переходов через автодороги, железные дороги и инженерные коммуникации
      kbd: 1, // поправочный коэффициент, учитывающий применение материалов и средств контроля при строительстве
      kkpz: 1, // поправочный коэффициент, учитывающий влияние применяемых ситем защиты от коррозии
      kper2: 5, //коэффициент, учитывающий прохождение трассы трубопровода через водные преграды и заболоченные участки
      kts: 0, //поправочный коэффициент, зависящий от толщины стенки трубопровода
      kzt: 0, //поправочный коэффициент, зависящий от минимальной глубины заложения трубопровода
      kkts: 0, //поправочный коэффициент, учитывающий влияние толщины стенки трубопровода на частоту разгерметизации по причине коррозии
      kdgd: 0,
      kop: 0,
      f: {
        f11: 0, f21: 0, f31: 0, f41: 0, f51: 0, f61 : 0,
        f12: 0, f22: 0, f32: 0, f42: 0, f52: 0, f62: 0,
        f13: 0, f23: 0, f33: 0, f43: 0, f53: 0, f63: 0
      },
      failureRateFirstType:0,
      failureRateSecondType: 0, 
      failureRateThirdType: 0,
      holeDiameterFirstType: 0,
      holeDiameterSecondType: 0,
      holeDiameterThirdType: 0,
    }
  },
  methods: {
    calculate() {
      this.directionalDrillPiece === 'yes' ? this.knnb = 0 : this.knnb = 1
      this.crossingArtificialObstacles === 'yes' ? this.kper1 = 2 : this.kper1 = 1
      this.applicationOfMaterials === 'norm' ? this.kbd = 1 : this.kbd = 0.07
      this.corrosionProtection === 'norm' ? this.kkpz = 1 : this.kkpz = 0.16
      this.kts = Math.exp(-0.275 * (this.wallThickness - 6));
      
      if (this.crossingNaturalObstacles === 'water') {
			  this.kper2 = 5;
      } else if (this.crossingNaturalObstacles === 'swamp') {
        this.kper2 = 2;
      } else if (this.crossingNaturalObstacles === 'no') {
        this.kper2 = 1;
      }

      if (this.layingDepth < 0.8) {
        this.kzt = 1;
      } else if (this.layingDepth >= 0.8 && this.layingDepth <= 1) {
        this.kzt = 0.93
      } else if (this.layingDepth > 1) {
        this.kzt = 0.73;
      }   

      if (this.wallThickness < 5) {
        this.kkts = 2;
      } else if (this.wallThickness >= 5 && this.wallThickness <= 10) {
        this.kkts = 1
      } else if (this.wallThickness > 10) {
        this.kkts = 0.03;
      }

      this.kdgd = Math.exp(-0.00156 * (this.pipelineDiameter - 274));
      this.kop = Math.exp(-0.004 * (this.pipelineDiameter - 264));
      
      if (this.typeOfPipeline === 'gasPipeline') {

        this.baselineFailureRate = 1.4e-7;
        
        this.f.f11 = 13.2 * this.kts * this.kzt * this.knnb * this.kper1;
        this.f.f21 = 10.6 * this.kbd;
        this.f.f31 = 15.2 * this.kkts * this.kkpz;
        this.f.f41 = 1.8 * this.kdgd * this.kper2;
        this.f.f51 = 3 * this.kop;
        this.f.f61 = 6.5;

        this.f.f12 = 26.6 * this.kts * this.kzt * this.knnb * this.kper1;
        this.f.f22 = 4.7 * this.kbd;
        this.f.f32 = 0.2 * this.kkts * this.kkpz;
        this.f.f42 = 2.2 * this.kdgd * this.kper2;
        this.f.f52 = 1.6 * this.kop;
        this.f.f62 = 0.2;

        this.f.f13 = 9.7 * this.kts * this.kzt * this.knnb * this.kper1;
        this.f.f23 = 1.2 * this.kbd;
        this.f.f33 = 0 * this.kkts * this.kkpz;
        this.f.f43 = 3.3 * this.kdgd * this.kper2;
        this.f.f53 = 0 * this.kop;
        this.f.f63 = 0;

        this.holeDiameterFirstType = 20;
        this.holeDiameterSecondType = 0.1 * this.pipelineDiameter;
        this.holeDiameterThirdType = +this.pipelineDiameter;

      }

      if (this.typeOfPipeline === 'oilPipeline') {

        this.baselineFailureRate = 2.7e-7
        
        this.f.f11 = 16.8 * this.kts * this.kzt * this.knnb * this.kper1;
        this.f.f21 = 11.3 * this.kbd;
        this.f.f31 = 15.2 * this.kkts * this.kkpz;
        this.f.f41 = 2.2 * this.kdgd * this.kper2;
        this.f.f51 = 3 * this.kop;
        this.f.f61 = 6.5;

        this.f.f12 = 26.2 * this.kts * this.kzt * this.knnb * this.kper1;
        this.f.f22 = 4.6 * this.kbd;
        this.f.f32 = 0.2 * this.kkts * this.kkpz;
        this.f.f42 = 2.2 * this.kdgd * this.kper2;
        this.f.f52 = 1.6 * this.kop;
        this.f.f62 = 0.2;

        this.f.f13 = 6.5 * this.kts * this.kzt * this.knnb * this.kper1;
        this.f.f23 = 0.6 * this.kbd;
        this.f.f33 = 0 * this.kkts * this.kkpz;
        this.f.f43 = 2.9 * this.kdgd * this.kper2;
        this.f.f53 = 0 * this.kop;
        this.f.f63 = 0;

        this.holeDiameterFirstType = Math.sqrt(0.0072) * this.pipelineDiameter;
        this.holeDiameterSecondType = Math.sqrt(0.0448) * this.pipelineDiameter;
        this.holeDiameterThirdType = Math.sqrt(0.179) * this.pipelineDiameter;
      }

      this.failureRateFirstType = this.baselineFailureRate * (this.f.f11 + this.f.f21 + this.f.f31 + this.f.f41 + this.f.f51 + this.f.f61) / 100;
      this.failureRateSecondType = this.baselineFailureRate * (this.f.f12 + this.f.f22 + this.f.f32 + this.f.f42 + this.f.f52 + this.f.f62) / 100;
      this.failureRateThirdType = this.baselineFailureRate * (this.f.f13 + this.f.f23 + this.f.f33 + this.f.f43 + this.f.f53 + this.f.f63) / 100;
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.calculate {
  width: 100%;
  padding-bottom: 18rem;

  &__title {
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 3.8rem;
    border-bottom: 2px solid $darkGrey;
    padding: 6rem 0 6.2rem;
  }

  &__subtitle {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: $lightBlack;
    margin-bottom: 3rem;
  }

  &__block {
    border-bottom: 1px solid $lightGrey;
    margin-top: 3rem;
  }

  &__button {
    margin-top: 5rem;
  }

  &__radio {
    margin-bottom: 3rem;

    .el-radio {
      display: flex;
      height: auto;
      margin-right: 0;
      margin-bottom: 1.6rem;

      &__label {
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: $basicBlack;
        padding-left: 1.2rem;
      }
    }

    .el-radio__input.is-checked + .el-radio__label {
      color: $basicBlack;
    }

    .el-radio__input.is-checked .el-radio__inner {
      border-color: $blue;
      background: $basicWhite;
    }

    .el-radio__inner::after {
      background: $blue;
      width: 10px;
      height: 10px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0;
    border-top: 1px solid $lightGrey;

    &:first-of-type {
      border-top: 0;
    }

    &-label {
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: $basicBlack;
    }

    &-input {
      width: 40rem;
    }
  }

  &__results {
    margin-top: 9rem;

    &-title {
      margin-bottom: 4rem;
      font-weight: 700;
      font-size: 2.8rem;
      line-height: 3.8rem;
    }

    &-blocks {
      display: flex;
    }

    &-block {
      width: calc(100% / 3);
    }

    &-name {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.5rem;
      color: $lightBlack;
      margin-bottom: 4rem;
    }

    &-text {
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: $basicBlack;
      margin-bottom: 2rem;
    }

    &-value {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $blue;
      margin-bottom: 8rem;
    }
  }
}
</style>
